import * as React from 'react'
import {useState} from 'react'
import {renderToString} from 'react-dom/server'
import {isMobile} from "./container/render";
import reactImageSize from 'react-image-size';

import * as workerSrc from "pdfjs-dist/build/pdf.worker.min";

let pdfjsLib = null;

if (typeof window !== `undefined` && pdfjsLib === null) {
    window.pdfjsWorker = workerSrc;

    if (!!window.msCrypto) {
        pdfjsLib = require('pdfjs-dist/legacy/build/pdf');
    } else {
        pdfjsLib = require('pdfjs-dist');
    }
    pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;
}

const svg = {
    quit: '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><g><path d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306C514.019,27.23,514.019,14.135,505.943,6.058z"></path></g></g><g><g><path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"></path></g></g></svg>',
    next: '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg>',
    prev: '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg>'
};

let resizeTimeout = null;
let htmlPages = [];
let open, setOpen;
let loading, setLoading;
let boxstyle, setBoxstyle;
let boxTransStyle, setBoxTransStyle;
let boxFacsStyle, setBoxFacsStyle;
let transcription, setTranscription;
let htmlPage, setHtmlPage;
let htmlImage, setHtmlImage;
let mode, setMode;
let elId, setElId;

let curPage = 0;
let curPdf = null;
let translationPages = [];
let pdfName = null;
let isRendering = false;

const MATGIN = 100;

const findDataElement = (el) => {
    while ((el = el.parentElement) && !el.getAttribute('data-pdf')) ;
    return el;
}

/**
 *
 * @param pdf
 * @param translation
 * @param elId
 * @param event
 */
const pdfOpen = (pdf, translation, elId, event) => {
    setMode('pdf');
    setElId(elId);
    setHtmlPage('');
    let target;
    if (event === null) {
        target = document.getElementById(elId);
    } else {
        target = findDataElement(event.target);
    }
    target.classList.add('loading');

    show();
    pdfName = pdf.replace(/^.*\//, '');
    translationPages = translation === undefined ? [] : translation;
    if (event !== null && (event.type === 'keyup' && event.key !== 'Enter')) {
        target.classList.remove('loading');
        return;
    }
    setLoading(true);
    loadPdf(pdf).then(() => {
        target.classList.remove('loading');
    });
}

/**
 *
 * @param pages
 * @param elId
 */
const htmlOpen = (pages, elId) => {
    setMode('html');
    setElId(elId);
    setTranscription('');
    show();
    htmlPages = pages;
    curPdf = {numPages: Object.keys(pages).length};
    getHtmlPage(1);
}

/**
 *
 * @param pages
 * @param pageNr
 */
const getHtmlPage = async (pageNr) => {
    curPage = pageNr;
    setHtmlImage(htmlPages[pageNr][0]);
    setHtmlPage(htmlPages[pageNr][1].default());

    const image = await reactImageSize(htmlPages[pageNr][0]);
    let imageW = image.width;
    let imageH = image.height;

    let viewport = {
        isImage: true
    };

    if (isMobile(true)) {
        scrollLeft();
        let scale = (window.innerHeight - MATGIN) / imageH;
        viewport.width = imageW * scale;
        viewport.height = imageH * scale;

        if (viewport.width > window.innerWidth) {
            scale = (window.innerWidth - 20) / imageW;
            viewport.width = imageW * scale;
            viewport.height = imageH * scale;
        }
    } else {
        let scale = window.innerWidth / 2.5 / imageW;
        viewport.width = imageW * scale;
        viewport.height = imageH * scale;
        if (viewport.height > window.innerHeight - MATGIN) {
            scale = (window.innerHeight - MATGIN) / imageH;
            viewport.width = imageW * scale;
            viewport.height = imageH * scale;
        }
    }

    updateSizes(viewport);
}

/**
 *
 * @param pdf
 * @param pageNr
 */
const getPage = (pdf, pageNr) => {

    setTranscription('');
    curPage = pageNr;
    pdf.getPage(pageNr).then(function (page) {

        let pageW = parseInt(page.getViewport(1).viewBox[2].toString());
        let pageH = parseInt(page.getViewport(1).viewBox[3].toString());

        const noTranslation = translationPages.length < 1;
        pageW = pageW < 1 ? 1 : pageW;
        pageH = pageH < 1 ? 1 : pageH;

        let viewport;

        if (isMobile(true)) {
            scrollLeft();
            viewport = page.getViewport({scale: (window.innerHeight - MATGIN) / pageH});

            if (viewport.width > window.innerWidth) {
                viewport = page.getViewport({scale: (window.innerWidth - 20) / pageW});
            }
        } else {
            viewport = page.getViewport({scale: window.innerWidth / (noTranslation ? 1 : 2.5) / pageW});
            if (viewport.height > window.innerHeight - MATGIN) {
                viewport = page.getViewport({scale: (window.innerHeight - MATGIN) / pageH});
            }
        }

        let canvas = document.querySelector('#lightbox-facsimile .facsimile');

        if (canvas === null) {
            return;
        }

        canvas.height = viewport.height;
        canvas.width = viewport.width;
        let renderContext = {
            canvasContext: canvas.getContext('2d'),
            viewport: viewport
        };

        if (!isRendering) {
            isRendering = true;
            page.render(renderContext).promise.then(function () {
                isRendering = false;
                if (translationPages.length > 0) {
                    loadTranscription(pageNr, viewport);
                } else {
                    updateSizes(viewport);
                }
                setLoading(false);
            });
        }
    });
}

/**
 *
 * @param url
 * @returns {Promise<unknown>}
 */
const loadPdf = (url) => {
    let loadingTask = pdfjsLib.getDocument(url);

    return loadingTask.promise.then(function (pdf) {
        curPdf = pdf;
        getPage(pdf, 1);
        setTimeout(function () {
            setLoading(false);
        }, 1000);
    });
}

/**
 *
 * @param pageNr
 * @param viewport
 */
const loadTranscription = (pageNr, viewport) => {
    if (translationPages[pdfName] !== undefined && translationPages[pdfName][pageNr] !== undefined) {
        setTranscription(renderToString(translationPages[pdfName][pageNr].default()));
    } else {
        setTranscription('');
    }

    updateSizes(viewport);
}

/**
 *
 * @param viewport
 */
const updateSizes = (viewport) => {

    if (isMobile(true)) {
        const offsetX = (window.innerWidth - viewport.width) / 2;
        setBoxstyle({
            width: (window.innerWidth * 2) + 'px',
            maxWidth: (window.innerWidth * 2) + 'px',
            height: (window.innerHeight - MATGIN) + 'px',
            left: 0,
            top: MATGIN / 2
        });

        if (viewport.isImage === true) {
            setBoxFacsStyle({
                top: 0,
                left: offsetX + 'px',
                width: viewport.width,
                height: viewport.height
            });
        } else {
            setBoxFacsStyle({
                top: 0,
                left: offsetX + 'px',
                width: 'unset',
                height: 'unset'
            });
        }

        setBoxTransStyle({
            height: (window.innerHeight - MATGIN) + 'px',
            width: (window.innerWidth - MATGIN) + 'px',
            left: (window.innerWidth + (MATGIN / 2)) + 'px'
        });
    } else {
        setBoxFacsStyle({
            width: viewport.isImage === true ? viewport.width : 'auto',
            height: viewport.isImage === true ? viewport.height : 'auto'
        });

        const noTranslation = translationPages.length < 1 && viewport.isImage !== true;
        const multip = noTranslation ? 1 : 2;

        setBoxstyle({
            width: (viewport.width * multip) + 'px',
            height: viewport.height + 'px',
            left: ((window.innerWidth - viewport.width * multip) / 2) + 'px'
        });

        const height = viewport.height < 500 ? 500 : viewport.height;
        setBoxTransStyle({
            height: noTranslation ? 0 : height + 'px',
            left: noTranslation ? 0 : viewport.width + 'px',
            width: noTranslation ? 0 : viewport.width + 'px'
        });
    }

}

const focusFacsimileButton = () => {
    let btn = document.querySelector('.lightbox-facsimile-buttons button[aria-hidden="false"]');
    if (btn !== null) {
        setTimeout(function () {
            btn.focus();
        });
    }
}

const lightboxFacsimileEvents = (event) => {
    switch (event.key.toLowerCase()) {
        case 'tab':
            if (!document.activeElement.parentElement.classList.contains('lightbox-facsimile-buttons')) {
                focusFacsimileButton();
            }
            break;

        case 'escape':
            hide();
            break;

        case 'arrowright':
            nextLightboxFacsimilePage();
            break;

        case 'arrowleft':
            prevLightboxFacsimilePage();
            break;

        default:
            break;
    }
}

const nextLightboxFacsimilePage = () => {
    if (curPage < curPdf.numPages) {
        if (mode === 'pdf') {
            getPage(curPdf, curPage + 1);
        } else {
            getHtmlPage(curPage + 1);
        }
    }
}

const prevLightboxFacsimilePage = () => {
    if (curPage > 1) {
        if (mode === 'pdf') {
            getPage(curPdf, curPage - 1);
        } else {
            getHtmlPage(curPage - 1);
        }
    }
}

const afterResize = () => {
    if (resizeTimeout !== null) {
        clearTimeout(resizeTimeout);
        resizeTimeout = null;
    }

    resizeTimeout = setTimeout(function () {
        if (mode === 'pdf') {
            getPage(curPdf, curPage);
        } else {
            getHtmlPage(curPage);
        }
    }, 200);
}

const show = () => {
    setOpen(true);
    document.querySelector('body').classList.add('fixed');
    window.addEventListener('keyup', lightboxFacsimileEvents);
    window.addEventListener('resize', afterResize);
}

const hide = (event) => {

    if (event.target.getAttribute('id') === 'lightbox-facsimile' || event.target.classList.contains('quit')) {
        if (isMobile(true)) {
            document.body.classList.remove('mobile');
        }
        setOpen(false);
        document.querySelector('body').classList.remove('fixed');
        window.removeEventListener('keyup', lightboxFacsimileEvents);
        window.removeEventListener('resize', afterResize);
    }
}

const scrollLeft = () => {
    const target = document.getElementById('lightbox-facsimile');
    target.scrollBy({
        top: 0,
        left: -1 * target.getBoundingClientRect().width,
        behavior: "smooth"
    });
}

const scrollRight = () => {
    const target = document.getElementById('lightbox-facsimile');
    target.scrollBy({
        top: 0,
        left: target.getBoundingClientRect().width,
        behavior: "smooth"
    });
}

/**
 *
 * @param mode
 * @returns {JSX.Element}
 */
const containerByMode = (mode) => {
    if (mode === 'pdf') {
        if (transcription !== undefined && transcription.length > 0) {
            return (
                <>
                    <canvas className="facsimile" style={boxFacsStyle} onContextMenu={(event) => {
                        event.preventDefault();
                    }}/>
                    <div className="text" style={boxTransStyle} dangerouslySetInnerHTML={{__html: transcription}}/>
                </>
            );
        } else {
            return (
                <>
                    <canvas className="facsimile" style={boxFacsStyle} onContextMenu={(event) => {
                        event.preventDefault();
                    }}/>
                </>
            );
        }
    }
    if (mode === 'html') {
        return (
            <>
                {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                <img src={htmlImage} style={boxFacsStyle} alt="" onContextMenu={(event) => {
                    event.preventDefault();
                }}/>
                {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                <div className="text" style={boxTransStyle}>{htmlPage}</div>
            </>
        );
    }

    return (
        <></>
    );
}

const FacsimileLightbox = () => {
    [open, setOpen] = useState(false);
    [transcription, setTranscription] = useState('');
    [mode, setMode] = useState('html');
    [elId, setElId] = useState('');
    [htmlPage, setHtmlPage] = useState('');
    [htmlImage, setHtmlImage] = useState('');
    [boxstyle, setBoxstyle] = useState({width: '0', height: '0', left: '0'});
    [boxTransStyle, setBoxTransStyle] = useState({height: '0', left: '0'});
    [boxFacsStyle, setBoxFacsStyle] = useState({left: '0'});
    [loading, setLoading] = useState(false);

    if (!open) {
        return (
            <span/>
        )
    }

    let scrollButtons = (null);
    if (isMobile(true) && (transcription.length > 0 || mode === 'html')) {
        scrollButtons = (
            <>
                <button className="scrollRight" onClick={scrollRight}>zur Transkription</button>
                <button className="scrollLeft" onClick={scrollLeft}>zum Faksimile</button>
            </>
        );
    }

    return (
        <div className={loading ? 'lightbox-facsimile glightbox-clean' : 'lightbox-facsimile glightbox-clean show'}
             id="lightbox-facsimile"
             onClick={hide}
             onKeyPress={hide}
             role="button"
             tabIndex="-1"
             data-elid={elId}
             aria-hidden={curPdf === null}>

            <div className="container" style={boxstyle}>
                {containerByMode(mode)}
                {scrollButtons}
                <div className="lightbox-facsimile-buttons">
                    <button className="gnext gbtn next" aria-label="Seite vor"
                            aria-hidden={curPdf === null || curPdf.numPages === curPage}
                            onClick={nextLightboxFacsimilePage} dangerouslySetInnerHTML={{__html: svg.next}}/>
                    <button className="gprev gbtn prev" aria-label="Seite zurück" aria-hidden={curPage === 1}
                            onClick={prevLightboxFacsimilePage} dangerouslySetInnerHTML={{__html: svg.prev}}/>
                    <button className="gclose gbtn quit" aria-label="lightbox schliessen" aria-hidden="false"
                            onClick={hide} dangerouslySetInnerHTML={{__html: svg.quit}}/>
                </div>
            </div>
            <button className="end" tabIndex="0" aria-hidden="true" onFocus={focusFacsimileButton}/>
        </div>
    )
}

export
{
    FacsimileLightbox, pdfOpen, htmlOpen, loading, nextLightboxFacsimilePage, prevLightboxFacsimilePage
}
