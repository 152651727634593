import * as React from 'react'
import * as Nav from "./mainNav";
import {Sprungmarken} from "./mainNav";
import {graphql, Link, useStaticQuery} from 'gatsby'
import * as Container from "./container/container";

const Header = ({props}) => {

    const query = useStaticQuery(graphql`
    query {
      gitCommit(latest: { eq: true }) {
        hash
      }
      site {
        buildTime
      }
    }
  `);

    const buildTime = new Date(query.site.buildTime);
    const hash = query.gitCommit.hash;

    const version = 'Version: ' +
        buildTime.toLocaleDateString() + ' ' + buildTime.toLocaleTimeString() +
        ' [' + hash.substr(0, 8) + ']';

    return (
        <header id="header">
            <div className="container">
                <div className="row">
                    <div className="col-9 logo-row">
                        <div className="logo-blt">
                            <img src={"/images/BLT_Logo.svg"} alt="Logo Bayerischer Landtag"/>
                            <Link to="/"><img className="terminal" src={"/images/BLT_Logo_blue.svg"}
                                              alt="Logo Bayerischer Landtag"/></Link>
                            <Container.If env="t">
                                <div className="build-version">{version}</div>
                            </Container.If>
                        </div>
                        <div className="logo-odd">
                            <Link to="/"><img src={"/images/Logo_ODD.svg"}
                                              alt="Logo Orte der Demokratie - öffnet die Startseite"/></Link>
                        </div>
                    </div>
                    <Nav.MainNav/>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Nav.Breadcrumb path={props.path}/>
                    </div>
                    <div className="col-6">
                        <Sprungmarken path={props.path}/>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
