import * as React from 'react'

import Header from './header'
import Footer from './footer'
import {checkForQR, checkForTabNav, checkOpenMenu} from "./mainNav";
import {FacsimileLightbox, nextLightboxFacsimilePage, prevLightboxFacsimilePage} from "./facsimileLightbox";
import Glightbox from "./glightbox";
import {Helmet} from "react-helmet";
import {isLandscape, isTerminal, setImgContext} from "./container/container";
import {useSwipeable} from "react-swipeable";
import 'objectFitPolyfill';

import "../sass/style.scss"

const Layout = ({pageTitle, children, props, className, pageClass}) => {

    React.useEffect(() => {
        Glightbox();

        if (typeof window !== `undefined`) {

            if (!isTerminal()) {
                const params = new URLSearchParams(window.location.search);
                let imId = decodeURI(params.get('imid'));
                if (imId) {
                    imId = atob(imId);
                    setTimeout(() => {
                        const target = document.querySelector('img[src="' + imId + '"]');
                        if (target) {
                            target.click();
                        }
                    });
                }
                window.objectFitPolyfill();
            }

            if (!!window.msCrypto) {
                document.querySelector('body').classList.add('ie');
            }

            window.addEventListener('click', checkOpenMenu.bind(this));
            window.addEventListener('keyup', checkForTabNav.bind(this));

            if (process.env.NODE_ENV === 'development') {
                window.addEventListener('keyup', checkForQR.bind(this));
            }

            window.addEventListener('scroll', () => {
                if (window.scrollY > 200) {
                    document.getElementById('toTop').classList.add('show');
                } else {
                    document.getElementById('toTop').classList.remove('show');
                }
            });

            setImgContext();

            return () => {
                window.removeEventListener('click', checkOpenMenu.bind(this));
                window.removeEventListener('keyup', checkForTabNav.bind(this));
            }
        }

    }, []);

    let bodyClass = isTerminal() ? 'terminal' : '';
    bodyClass += isLandscape() ? ' landscape' : '';
    bodyClass = pageClass !== undefined ? bodyClass + ' ' + pageClass : bodyClass;
    bodyClass = bodyClass.trim();

    const back = () => {
        window.location.href = "/";
    }

    const scrollToTop = (event) => {

        if (event.type.toLowerCase() === "keyup" && event.key.toLowerCase() !== "enter") {
            return;
        }

        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    let backButton = (null);

    if (props.path !== '/' && isTerminal()) {
        backButton = (
            <div id="back" onClick={back} onKeyUp={back} role="button" tabIndex="0"
                 aria-label="Zurück"/>
        );
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            nextLightboxFacsimilePage();
        },
        onSwipedRight: () => {
            prevLightboxFacsimilePage();
        },
    });

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <body className={bodyClass}/>
                <html className={pageClass} lang="de"/>
            </Helmet>
            <main className={className}>
                <Header props={props}/>
                {children}
            </main>
            <Footer env="w"/>
            <div {...handlers}>
                <FacsimileLightbox/>
            </div>
            <div id="toTop" onClick={scrollToTop} onKeyUp={scrollToTop} role="button" tabIndex="0"
                 aria-label="Nach oben"/>
            {backButton}
        </>
    )
}

export default Layout
