import {getPageName} from "../mainNav";
import {renderToString} from 'react-dom/server'

/**
 *
 * @param parts
 * @param ortName
 * @returns {[undefined, (JSX.Element|null)]}
 * @constructor
 */
const Render = ({parts, ortName}) => {
    return (
        [
            parts.map(function (item, index) {
                return item(index, ortName);
            }),
            //Container.isTerminal()
            //    ? (<Media elements={MediaContainer.getElements(parts)} ortName={ortName}/>)
            //    : (null)
        ]
    )
}

/**
 *
 * @param part
 * @returns {*}
 * @constructor
 */
const RenderOrt = (part) => {
    if (part !== undefined) {
        return renderToString(part.default());
    }
}

/**
 *
 * @param path
 * @returns {*}
 * @constructor
 */
const OrtName = (path) => {
    return getPageName(path);
}

/**
 *
 * @returns {boolean}
 */
const isMobile = (isFacsimile) => {
    if (typeof window !== `undefined`) {

        if (isFacsimile === true) {
            const mobile = window.innerWidth <= 850;

            if (mobile) {
                document.body.classList.add('mobile');
            } else {
                document.body.classList.remove('mobile');
            }

            return mobile;
        }

        return window.innerWidth <= 767;
    }
    return false;
}

export {Render, RenderOrt, OrtName, isMobile}
