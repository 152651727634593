import React from "react";
import Glide from '@glidejs/glide'
import {isMobile} from "./container/render";

/**
 *
 * @param count
 * @param userConfig
 * @returns {JSX.Element}
 */
const addBullets = (count, userConfig) => {
    if (count < 1) {
        return (<div/>);
    }

    let config = getConfig(userConfig, null);

    let perViews = {
        '0': parseInt(config['perView'].toString())
    };
    for (let key of Object.keys(config['breakpoints'])) {
        perViews[key] = parseInt(config['breakpoints'][key]['perView']);
    }

    let bullets = '';
    for (let i = 0; i < count; i++) {
        let classes = 'glide__bullet ';
        for (let key of Object.keys(perViews)) {
            if ((i > 0 && i % perViews[key] === 0) ||
                (i === 0 && perViews[key] < count)
            ) {
                classes += ' bull' + key;
            }
        }
        bullets += '<button class="' + classes + '" data-glide-dir="=' + i + '"><span class="sr-invisible">Seite ' +
            (i + 1) + '</span></button>';
    }

    return (
        <div className="glide-controls glide__bullets" data-glide-el="controls[nav]"
             dangerouslySetInnerHTML={{__html: bullets}}/>
    );
}

/**
 *
 * @param userConfig
 * @param sliderTitleId
 * @returns {{perView: number, breakpoints: {"991": {perView: number}, "575": {perView: number}, "1279": {perView: number}, "767": {perView: number}}, type: string}}
 */
const getConfig = (userConfig, sliderTitleId) => {

    userConfig = userConfig === undefined ? null : userConfig;

    let config = {
        type: 'carousel',
        perView: 3,
        breakpoints: {
            1279: {perView: 4},
            991: {perView: 3},
            767: {perView: 2},
            575: {perView: 1}
        }
    };

    if (userConfig !== null) {
        try {
            userConfig = typeof userConfig === 'object'
                ? userConfig
                : JSON.parse(userConfig.replace(/'/g, '"'));
            for (let key of Object.keys(userConfig)) {
                config[key] = userConfig[key];
            }
        } catch (e) {
            console.error(e);
        }
    }

    if (sliderTitleId !== undefined && sliderTitleId !== null) {
        config['peek'] = getPeek(sliderTitleId);
    }
    return config;
}

/**
 *
 * @param sliderTitleId
 */
const getPeek = (sliderTitleId) => {
    if (sliderTitleId !== null && sliderTitleId !== undefined) {
        const title = document.getElementById(sliderTitleId);
        if (title !== null) {
            let peek = title.getBoundingClientRect().left;
            peek = peek < 60 ? peek + 60 : peek;

            return {
                before: peek + 'px',
                after: peek + 'px',
            };
        }
    }
    return {};
}

let margins = {};

/**
 *
 * @param Glide
 * @param Components
 * @param Events
 * @returns {{modify(*): (*)}|number|*}
 */
const modTranslate = (Glide, Components, Events) => {
    return {
        modify(translate) {
            if (!isMobile() && Glide['selector'].startsWith('#novel_') && document.querySelector('.container h1')) {
                if (margins[Glide['selector']] === undefined) {
                    margins[Glide['selector']] = translate +
                        document.querySelector('.container h1').getBoundingClientRect().left - (isMobile() ? 20 : 0);
                }
                return translate - margins[Glide['selector']];
            }

            return translate;
        }
    }
}

/**
 *
 * @param sliderId
 * @param sliderTitleId
 * @param userConfig
 * @param runAfter
 * @returns {Glide}
 */
const init = (sliderId, sliderTitleId, userConfig, runAfter) => {
    try {
        if (document.getElementById(sliderId) === null) {
            return null;
        }

        const config = getConfig(userConfig, sliderTitleId);

        const glide = new Glide('#' + sliderId, config)
            .mutate([modTranslate])
            .mount()
            .on(['run.after'], () => {
                if (typeof runAfter === 'function') {
                    runAfter();
                }
            });

        let resizeTimeout = null;

        glide.on(['resize'], () => {
            if (resizeTimeout !== null) {
                clearTimeout(resizeTimeout);
                resizeTimeout = null;
            }

            resizeTimeout = setTimeout(() => {
                margins = {};
                glide.index = 0;
                if (sliderTitleId !== undefined && sliderTitleId !== null) {
                    glide.update({peek: getPeek(sliderTitleId)});
                } else {
                    glide.update();
                }

                resizeTimeout = null;
            }, 300);
        });

        const slides = document.querySelectorAll('#' + sliderId + ' .glide__slide,#' + sliderId + ' .novel-glide__slide');
        let slidesCount = slides ? slides.length : 0;
        const isNovel = slides[0].classList.contains('novel-glide__slide');
        const offset = isNovel ? 1 : config['perView'];
        slidesCount = config['perView'] !== undefined ? slidesCount - offset : slidesCount;

        glide.on(['run'], () => {
            const left = document.querySelector('#' + sliderId + ' .glide__arrow--left');
            const right = document.querySelector('#' + sliderId + ' .glide__arrow--right');

            if (left) {
                if (glide.index === 0) {
                    left.classList.add('glide__arrow--hidden');
                } else {
                    left.classList.remove('glide__arrow--hidden');
                }
            }

            if (right) {
                if (glide.index < slidesCount) {
                    right.classList.remove('glide__arrow--hidden');
                } else {
                    right.classList.add('glide__arrow--hidden');
                }
            }

        })

        return glide;
    } catch (e) {
        console.error(e);
    }
}

export {init, addBullets, getConfig};

