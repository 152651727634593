import * as React from 'react'
import {Link} from "gatsby"
import {showOnEnv} from "./container/container";

const Footer = ({env}) => {
    return showOnEnv(env,
        (
            <footer id="footer">
                <div className="footer-menu">
                    <div className="container">
                        <div className="row">
                            <div className="col-4">
                                <h2>Adresse</h2>
                                <p>
                                    Bayerischer Landtag <br/>
                                    Landtagsamt <br/>
                                    Maximilianeum <br/>
                                    Max-Planck-Straße 1 <br/>
                                    81675 München
                                </p>
                            </div>
                            <div className="col-4">
                                <h2>Kontakt</h2>
                                <p>
                                    <span>Telefon: +49 89 4126-0</span> <br/>
                                    <span>E-Mail: <Link
                                        to="maitlo:landtag@bayern.landtag.de">landtag@bayern.landtag.de</Link></span><br/>
                                    <span>Web: <Link
                                        to="maitlo:landtag@bayern.landtag.de">www.bayern.landtag.de</Link></span>
                                </p>
                            </div>
                            <div className="col-4">
                                <h2>Service</h2>
                                <ul>
                                    <li><Link to="/sitemap">Sitemap</Link></li>
                                    <li><Link to="/wissenschaftlicher-beirat">Wissenschaftlicher Beirat</Link></li>
                                    <li><Link to="/gedenkobjekt">Gedenkobjekt</Link></li>
                                    <li><Link to="/ausstellung">Ausstellung</Link></li>
                                    <li><Link to="/impressum">Impressum </Link></li>
                                    <li><Link to="https://www.bayern.landtag.de/service/datenschutz/"
                                              target="_blank">Datenschutz</Link></li>
                                    <li><Link to="/barrierefreiheitserklaerung">Erklärung zur Barrierefreiheit</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-service">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        )
    );
}

export default Footer
