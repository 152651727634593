import * as React from 'react'
import {useState} from 'react'
import {Link} from 'gatsby'
import {isTerminal, showOnEnv} from "./container/container";

const sitePages = [
    {url: "/", label: "Startseite", className: "home icon-home"},
    {
        url: "/orte",
        label: "Orte der Demokratie",
        noLink: true,
        children: [
            {url: '/orte/memmingen', label: 'Memmingen'},
            {url: '/orte/regensburg', label: 'Regensburg'},
            {url: '/orte/gaibach', label: 'Gaibach'},
            {url: '/orte/muenchen-prannerstrasse', label: 'München - Prannerstraße'},
            {url: '/orte/bamberg', label: 'Bamberg'},
            {url: '/orte/vilshofen', label: 'Vilshofen'},
            {url: '/orte/nuernberg', label: 'Nürnberg'},
            {url: '/orte/wohlmuthshuell', label: 'Wohlmuthshüll'},
            {url: '/orte/muenchen-lmu', label: 'München - Große Aula der LMU'},
            {url: '/orte/herrenchiemsee', label: 'Herrenchiemsee'},
            {url: '/orte/passau', label: 'Passau'},
            {url: '/orte/ermershausen', label: 'Ermershausen'},
            {url: '/orte/muenchen-maximilianeum', label: 'München - Maximilianeum'},
        ]
    },
    {url: "/interaktive-karte", label: "Interaktive Karte"},
    {url: "/wissenschaftlicher-beirat", label: "Wissenschaftlicher Beirat"},
    {url: "/gedenkobjekt", label: "Gedenkobjekt"},
    {url: "/ausstellung", label: "Ausstellung"},
    {url: "https://www.blz.bayern.de/materialien-zur-politischen-bildungsarbeit.html", label: "Schulmaterialien", env: "w"},
    {
        url: "/datenschutz",
        label: "Datenschutz",
        extern: 'https://www.bayern.landtag.de/service/datenschutz/',
        hide: true,
        end: "w"
    },
    {url: "/impressum", label: "Impressum", hide: true},
    {url: "/barrierefreiheitserklaerung", label: "Erklärung zur Barrierefreiheit", hide: true},
    {url: "/sitemap", label: "Sitemap", hide: true},
    {url: "/verfassung_alt", label: "Verfassung des Freistaates Bayern vom 26. Oktober 1946", hide: true},
    {url: "/verfassung_neu", label: "Verfassung des Freistaates Bayern", hide: true},
    {url: "/biografie_lotzer", label: "Biografie von Sebastian Lotzer", hide: true},
    {url: "/biografie_schappeler", label: "Biografie von Christoph Schappeler", hide: true},
    {url: "/biografie_behr", label: "Biografie von Wilhelm Joseph Behr", hide: true},
];

let siteSubmenus, setSiteSubmenus;
let menuOpen, setMenuOpen;

/**
 *
 * @param pages
 * @param path
 * @returns {*}
 */
const getPageName = (path) => {
    path = path.replace(/\/$/, '');
    let page = findPageName(sitePages, path);
    return page === null ? path : page.label;
}

/**
 *
 * @param path
 * @returns {*}
 */
const getPage = (path) => {
    path = path.replace(/\/$/, '');
    let page = findPageName(sitePages, path);
    return page === null ? path : page;
}

/**
 *
 * @param pages
 * @param path
 * @returns {*}
 */
const findPageName = (pages, path) => {
    let page = null;
    Object.keys(pages).forEach((key) => {
        if (page === null && pages[key].children !== undefined) {
            page = findPageName(pages[key].children, path);
        }
        if (page === null && pages[key].url === path) {
            page = pages[key];
        }
        if (page !== null) {
            return page;
        }
    })
    return page;
}

/**
 *
 * @param pages
 * @returns {*[]}
 */
const buildMenu = (pages) => {
    let menu = [];

    pages.forEach((page) => {

        if (page.hide !== true) {
            if (page.children !== undefined && page.children.length > 0) {

                const keyButton = 'button-' + page.url;
                const keyUl = 'ul-' + page.url;
                let subMenu = buildMenu(page.children);

                if (siteSubmenus[keyButton] === undefined) {
                    siteSubmenus[keyButton] = false;
                }

                if (isTerminal()) {
                    menu.push(
                        showOnEnv(page.env,
                            (
                                <li key={page.url}>
                                    <button className={page.className + ' subButton'}
                                            tabIndex="-1">{page.label}</button>
                                    <button key={keyButton} className="open more" tabIndex="-1">&nbsp;</button>
                                    <ul key={keyUl}>{subMenu}</ul>
                                </li>
                            )
                        )
                    );

                } else {
                    menu.push(
                        showOnEnv(page.env,
                            (
                                <li key={page.url}>
                                    <button className={page.className + ' subButton'}
                                            tabIndex={menuOpen ? 0 : -1}
                                            onClick={toggleSubMenu.bind(this, keyButton)}
                                            aria-expanded={siteSubmenus[keyButton]}
                                    >{page.label}</button>
                                    <button key={keyButton}
                                            className={siteSubmenus[keyButton] ? "open more icon-plus" : "more icon-plus"}
                                            aria-expanded={siteSubmenus[keyButton]}
                                            aria-label="öffnet das Untermenü"
                                            onClick={toggleSubMenu.bind(this, keyButton)}
                                            tabIndex="-1">&nbsp;</button>
                                    <ul key={keyUl}>{subMenu}</ul>
                                </li>
                            )
                        )
                    );
                }

            } else {
                const url = page.extern === undefined ? page.url : page.extern;
                const target = page.extern === undefined ? '' : '_balnk';
                menu.push(
                    showOnEnv(page.env,
                        (
                            <li key={page.url}>
                                <Link className={page.className} to={url} target={target}
                                      tabIndex={menuOpen ? 0 : -1}>{page.label}</Link>
                            </li>
                        )
                    )
                );

            }
        }
    });

    return menu;
}

/**
 *
 * @param key
 */
const toggleSubMenu = (key) => {
    const sub = JSON.parse(JSON.stringify(siteSubmenus));
    sub[key] = !sub[key];
    setSiteSubmenus(sub);
}

/**
 *
 * @returns {boolean}
 */
const closeSubMenus = () => {
    let wasSubOpen = false;
    const sub = JSON.parse(JSON.stringify(siteSubmenus));

    Object.keys(sub).forEach((key) => {
        wasSubOpen ||= sub[key];
        sub[key] = false;
    });
    setSiteSubmenus(sub);

    return wasSubOpen;
}

/**
 *
 */
const toggleMenu = () => {
    if (!menuOpen) {
        setMenuOpen(true);
    } else {
        closeMenu();
    }
}

/**
 *
 */
const closeMenu = () => {
    setMenuOpen(false);
    setSiteSubmenus({});
}

/**
 *
 * @param el
 * @param parent
 * @returns {boolean|*}
 */
const hasParent = (el, parent) => {
    if (el === parent) {
        return true;
    }
    if (el.parentElement === null) {
        return false;
    }
    return hasParent(el.parentElement, parent);
}

/**
 *
 * @param event
 */
const checkOpenMenu = (event) => {
    if (menuOpen &&
        event.target !== null &&
        event.target.getAttribute('id') !== 'menu-button') {

        if (!hasParent(event.target, document.getElementById('main-nav'))) {
            closeMenu();
        }
    }
}

/**
 *
 * @param event
 */
const checkForTabNav = (event) => {

    switch (event.key.toLowerCase()) {
        case 'tab':
            document.querySelector('body').classList.add('tab-nav')
            checkOpenMenu(event);
            break;

        case 'escape':
            if (!closeSubMenus()) {
                closeMenu();
            }
            break;

        default:
            break;
    }
}

/**
 *
 * @param event
 */
const checkForQR = (event) => {

    if (event.key.toLowerCase() === 'q' && event.ctrlKey) {
        if (typeof window !== `undefined`) {

            let elId = null;
            let imId = null;
            if (document.querySelector('[data-elid]')) {
                const el = document.querySelector('[data-elid]');
                elId = el.getAttribute('data-elid');
            }

            if (document.getElementById('glightbox-slider')) {
                const img = document.querySelector('.gslide-image img');
                const src = img.getAttribute('src').substr(21);
                imId = btoa(src);
            }

            let url = window.location.protocol + '//' +
                window.location.host +
                '/qr?target=' + encodeURI(window.location.pathname);
            if (elId !== null) {
                url += '?elid=' + elId;
            }
            if (imId !== null) {
                url += '?imid=' + imId;
            }
            window.open(url, '_blank');
        }
    }
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const MainNav = () => {

    [siteSubmenus, setSiteSubmenus] = useState({});
    [menuOpen, setMenuOpen] = useState(false);
    let menu = buildMenu(sitePages);

    return (
        <div className="col-3">
            <button className={menuOpen ? "is-open" : ""} id="menu-button" aria-expanded={menuOpen}
                    onClick={toggleMenu}>
                Menü<span/>
            </button>
            <nav id="main-nav" aria-hidden={!menuOpen}>
                <ul key="ul-home" aria-hidden={!menuOpen}>
                    {menu}
                </ul>
            </nav>
        </div>
    )
}

/**
 *
 * @param path
 * @returns {JSX.Element}
 * @constructor
 */
const Breadcrumb = ({path}) => {

    if (path === '/') {
        return (<span/>);
    }

    let crumbs = [];
    crumbs.push(<li key="bc-home"><Link to='/'
                                        title="öffnet die Startseite Orte der Demokratie in Bayern">{getPageName('/')}</Link>
    </li>);

    path = path.toString().replace(/\//g, ' ').trim();
    let url = '/';
    const parts = path.split(' ');
    if (parts.length > 0) {
        parts.forEach((part) => {
            url += part + '/';
            const key = 'bc-' + url;
            const page = getPage(url);
            if (page.noLink === true) {
                crumbs.push(<li key={key}><span>{page.label}</span></li>);
            } else {
                crumbs.push(<li key={key}><Link to={url}>{page.label}</Link></li>);
            }
        });
    }

    return (
        <ol key="breadcrumb" className="breadcrumb">{crumbs}</ol>
    )
}

/**
 *
 * @param path
 * @returns {JSX.Element|null}
 * @constructor
 */
const Sprungmarken = ({path}) => {

    if (path.match(/\/orte\/.+/)) {
        return (
            <ul className="sprunganker">
                <li><Link className="icon-novel icon-bg" to="#novel_2">Comic</Link></li>
                <li><Link className="icon-video icon-bg" to="#trailer">Reportage</Link></li>
            </ul>
        );
    }

    /*
    if (path === '/') {
        return (
            <ul className="sprunganker">
                <li><Link className="icon-video icon-bg" to="#trailer">Trailer</Link></li>
            </ul>
        );
    }
    */
    return (null);
}

/**
 *
 * @param items
 * @returns {JSX.Element|null}
 */
const buildSitemap = (items) => {
    if (items === undefined || items.length < 1) {
        return (null);
    }

    return (
        <ul>
            {
                items.map(function (item, index) {
                    const url = item.extern === undefined ? item.url : item.extern;
                    const target = item.extern === undefined ? '_self' : '_blank';
                    return (
                        <li key={index}>
                            <Link to={url} target={target}>{item['label']}</Link>
                            {buildSitemap(item['children'])}
                        </li>);
                })
            }
        </ul>
    );
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Sitemap = () => {
    return (
        <>
            {buildSitemap(sitePages)}
        </>
    )
}

export {
    MainNav,
    Breadcrumb,
    Sprungmarken,
    Sitemap,
    getPageName,
    toggleMenu,
    checkOpenMenu,
    checkForTabNav,
    checkForQR
}
