import React, {Fragment, useRef, useState} from "react";
import * as PdfLightbox from "../facsimileLightbox";
import Glightbox from "../glightbox";
import * as Glide from '../slider';
import configJSON from '../../../odd-config.json';
import videojs from "video.js";

/**
 *
 * @param classNames
 * @returns {string}
 */
const mapBgClass = (classNames) => {
    if (classNames === undefined || classNames === null) {
        return '';
    }

    switch (classNames.toLowerCase()) {
        case 'bg-blue':
        case 'blue':
            return 'bg-blue';

        case 'bg-grey':
        case 'grey':
            return 'bg-grey';

        case 'bg-beige':
        case 'beige':
            return 'bg-beige';

        case 'bg-white':
        case 'white':
            return 'bg-white';

        default:
            return classNames;
    }
}

/**
 *
 * @param background
 * @param env
 * @param children
 * @returns {boolean}
 * @constructor
 */
const HeaderPart = ({background, env, children}) => {
    return showOnEnv(env,
        (
            <div className={background + " bg-white headerimage"}>
                {children}
            </div>
        )
    );
}

/**
 *
 * @param background
 * @param id
 * @param env
 * @param children
 * @returns {null|*}
 * @constructor
 */
const Part = ({background, id, env, children}) => {
    return showOnEnv(env,
        (
            <div className={mapBgClass(background)} id={id}>
                <div className="container">
                    {children}
                </div>
            </div>
        )
    );
}

/**
 *
 * @param background
 * @param env
 * @param children
 * @returns {boolean}
 * @constructor
 */
const Fullwidth = ({background, env, children}) => {
    return showOnEnv(env,
        (
            <div className={mapBgClass(background)}>
                {children}
            </div>
        )
    );
}

/**
 *
 * @param env
 * @param id
 * @param children
 * @returns {null|*}
 * @constructor
 */
const Row = ({env, id, children}) => {
    return showOnEnv(env,
        (
            <div className="row" id={id}>
                {children}
            </div>
        )
    );
}

/**
 *
 * @param env
 * @param children
 * @returns {JSX.Element|*}
 * @constructor
 */
const If = ({env, children}) => {

    return showOnEnv(env,
        (
            <Fragment>
                {children}
            </Fragment>
        )
    );
}

/**
 *
 * @param url
 * @param img
 * @param height
 * @param width
 * @param env
 * @returns {boolean}
 * @constructor
 */
const VideoYoutube = ({url, img, height, width, env}) => {
    img = img === undefined ? '/images/Logo-ODD.png' : img;

    const [video, setVideo] = useState(
        <div className="videoPreview">
            <div>Wenn Sie das Video aktivieren, werden Inhalte von YouTube geladen und dadurch Ihre u.A. IP-Adresse an
                 YouTube übertragen.
            </div>
            <img src={img} width={width} height={height} alt=""/>
        </div>
    );
    width = width === undefined ? '100%' : width;
    height = height === undefined ? 'auto' : height;

    if (!url.match(/autoplay/)) {
        url += url.match(/\?/) ? '&autoplay=1' : '?autoplay=1';
    }

    const showVideo = (event) => {
        if (event.type === 'keydown' && event.key !== 'Enter') {
            return;
        }

        setVideo(
            <iframe width={width} height={height} src={url}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        );
    }

    return showOnEnv(env,
        (
            <div className="videoContainer" data-url={url}
                 style={{width: width + 'px', height: height + 'px'}} onClick={showVideo} role="button"
                 onKeyDown={showVideo}
                 tabIndex="0">
                {video}
            </div>
        )
    );
}

/**
 *
 * @param poster
 * @returns {string|*}
 */
const getPoster = (poster) => {
    return (poster === undefined || poster === '') ? '/images/Logo_ODD.svg' : poster;
}

const initVideoJs = (id, onPause, onPlay, onEnded) => {
    return videojs(
        id,
        {
            controlBar: {
                fullscreenToggle: false,
                pictureInPictureToggle: false,
                playToggle: false,
                captionsButton: false,
                chaptersButton: false,
                subtitlesButton: false,
                remainingTimeDisplay: false,
                progressControl: {seekBar: true},
            },
            userActions: {
                doubleClick: false
            },
            controls: true,
            autoplay: false,
            preload: 'none',
            loadingSpinner: true,
            bigPlayButton: true,
            loop: false,
            responsive: true,
            width: 800,
        },
        function onPlayerReady() {
            this.on("fullscreenchange", function () {
                if (this.isFullscreen()) {
                    this.exitFullscreen();
                }
            });

            this.on('pause', () => {
                onPause();
            });

            this.on('play', () => {
                onPlay();
            });

            this.on('ended', () => {
                onEnded();
            });
            this.on('touchstart', function (e) {
                if (e.target.nodeName === 'VIDEO') {
                    if (this.paused()) {
                        this.play();
                    } else {
                        this.pause();
                    }
                }
            });
        }
    );
}

/**
 *
 * @param url
 * @param poster
 * @param height
 * @param width
 * @param env
 * @returns {*|null}
 * @constructor
 */
const Video = ({url, poster, height, width, env}) => {

    const id = 'video-' + url.replace(/[^\w]*/g, '');

    const resizeVideo = () => {
        document.querySelectorAll('.videoContainer').forEach((container) => {
            const video = container.firstElementChild;
            const width = container.getBoundingClientRect().width;
            video.style.width = width + 'px';
            video.style.height = (width * 9 / 16) + 'px';
        })
    }

    React.useEffect(() => {
        if (!isTerminal()) {
            resizeVideo(id);
            window.addEventListener('resize', resizeVideo);
            return;
        }

        let restarttimeout;
        const pausetimeout = 60; /* in s */
        const defaultvolume = .5; /* 0..1 */

        const playbutton = document.getElementById('videoContainer-play');
        const startbutton = document.getElementById('videoContainer-back');
        const animatedarc = document.getElementById('videoContainer-arc1');
        const posterbutton = document.getElementById('videoContainer-posterbutton');
        const continuebutton = document.getElementById('videoContainer-continuebutton');

        const onPause = () => {
            startbutton.style.visibility = "visible";
            animatedarc.style.animation = 'dash ' + pausetimeout + 's linear forwards';
            restarttimeout = window.setTimeout(startposter, pausetimeout * 1000);
        }

        const onPlay = () => {
            startbutton.style.visibility = "hidden";
            animatedarc.style.animation = 'none';
            clearTimeout(restarttimeout);
        };

        const startposter = () => {
            startbutton.style.display = "none";
            playbutton.style.visibility = "visible";
            videoplayer.controls(false);
            videoplayer.loop(true);
            videoplayer.src(url)
            videoplayer.muted(true);
        }

        const videoplayer = initVideoJs(id, onPause, onPlay, startposter);

        const startvideo = () => {
            startbutton.style.display = "flex";
            playbutton.style.visibility = "hidden";
            videoplayer.controls(true);
            videoplayer.loop(false);
            videoplayer.src(url)
            videoplayer.muted(false);
            videoplayer.volume(defaultvolume);
            videoplayer.play();
        }

        playbutton.addEventListener('click', startvideo);
        posterbutton.addEventListener('click', startposter);
        continuebutton.addEventListener('click', () => {
            videoplayer.play();
        });

        startposter();
    }, [id, poster, url]);

    if (!isTerminal()) {
        return showOnEnv(env,
            (
                <div className="videoContainer">
                    {/* eslint-disable jsx-a11y/media-has-caption */}
                    <video className="videoPlayer" poster={getPoster(poster)} controls width={width} height={height}>
                        <source src={url} type="video/mp4"/>
                    </video>
                    {/* eslint-enable jsx-a11y/media-has-caption */}
                </div>
            )
        );
    }

    return showOnEnv(env,
        (
            <>
                <div className="videoContainer">
                    {/* eslint-disable jsx-a11y/media-has-caption */}
                    <video id={id} className="video-js vjs-default-skin vjs-big-play-centered" poster={getPoster(poster)}>
                        <source src={url} type="video/mp4"/>
                    </video>
                    {/* eslint-enable jsx-a11y/media-has-caption */}

                    <div id="videoContainer-play">
                        <svg viewBox="0 0 48 48" width="150" height="150">
                            <path fill="#ffffff" fill-opacity="0.9"
                                  d="M24 0A24 24 0 000 24a24 24 0 0024 24 24 24 0 0024-24A24 24 0 0024 0zm-8 11.3l11 6.4L38 24l-11 6.3-11 6.4V24z"/>
                        </svg>
                    </div>
                    <div id="videoContainer-back">
                        <div id="videoContainer-posterbutton">
                            <svg viewBox="0 0 48 48" width="150" height="150">
                                <path fill="#ffffff" fill-opacity="0.8"
                                      d="M24 0A24 24 0 000 24a24 24 0 0024 24 24 24 0 0024-24A24 24 0 0024 0zM11 11.3h4V24l11-6.3 11-6.4v25.4l-11-6.4L15 24v12.7h-4z"/>
                                <path id="videoContainer-arc1" fill="none" stroke="#008cd2" stroke-width="3"
                                      d="M 24 1.5 A 22.5 22.5 0 1 1 23.999 1.5"/>
                            </svg>
                        </div>
                        <div id="videoContainer-continuebutton">
                            <svg viewBox="0 0 48 48" width="150" height="150">
                                <path fill="#ffffff" fill-opacity="0.8"
                                      d="M24 0A24 24 0 000 24a24 24 0 0024 24 24 24 0 0024-24A24 24 0 0024 0zm-8 11.3l11 6.4L38 24l-11 6.3-11 6.4V24z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </>
        )
    );
}

/**
 *
 * @param pdf
 * @param img
 * @param translation
 * @param env
 * @param children
 * @returns {null|*}
 * @constructor
 */
const Facsimile = ({pdf, img, translation, env, children, alttext, pages, text}) => {

    const elId = buildId(img);

    if (pdf !== undefined && pdf !== null) {

        const onKey = (pdf, translation, event) => {
            if (event.key === 'Enter') {
                PdfLightbox.pdfOpen(pdf, event, translation);
            }
        }

        openElement(elId, () => {
            PdfLightbox.pdfOpen(pdf, translation, elId, null)
        });

        let content = '';
        if (text !== undefined && text !== '') {
            content = (
                <div className="icon-arrow_right icon-bg">{text}</div>
            );
        } else {
            content = (
                <figure className="lightbox">
                        <span className="fac">
                            <img src={img} alt={alttext}/>
                        </span>
                    {children}
                </figure>
            );

        }

        return showOnEnv(env,
            (
                <div tabIndex="0" data-pdf={pdf} role='button'
                     id={elId}
                     onClick={PdfLightbox.pdfOpen.bind(this, pdf, translation, elId)}
                     onKeyUp={onKey.bind(this, pdf, translation, elId)}>
                    {content}
                </div>
            )
        );

    } else {

        const onKey = (pages, event) => {
            if (event.key === 'Enter') {
                PdfLightbox.htmlOpen(pages, elId);
            }
        }

        openElement(elId, () => {
            PdfLightbox.htmlOpen(pages, elId)
        });

        return showOnEnv(env,
            (
                <div tabIndex="0" data-pdf="html" role='button'
                     id={buildId(img)}
                     onClick={PdfLightbox.htmlOpen.bind(this, pages, elId)}
                     onKeyUp={onKey.bind(this, pages, elId)}>

                    <figure className="lightbox">
                        <span className="fac">
                            <img src={img} alt={alttext}/>
                        </span>
                        {children}
                    </figure>
                </div>
            )
        );
    }
}

///**
// *
// * @param pages
// * @constructor
// */
//const FacsimilePages = (pages) => {
//    if (Object.keys(pages).length < 1) {
//        return (<></>);
//    }
//    console.log(pages);
//    const pagesList = [];
//
//    Object.keys(pages).forEach((key) => {
//        pagesList.push(
//            <div key={key}>
//                {pages[key].default()}
//            </div>
//        );
//    });
//
//    return (
//        <div className="htmlFacsimile">
//            {pagesList}
//        </div>
//    );
//}

/**
 *
 * @param moreText
 * @param lessText
 * @param move
 * @param env
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const More = ({moreText, lessText, move, env, children}) => {

    return showOnEnv(env,
        (
            <div>
                {children}
            </div>
        )
    );

    //let [open, setOpen] = useState();
    //
    //if (isTerminal()) {
    //    return (
    //        <div>
    //            {children}
    //        </div>
    //    );
    //}
    //
    //const toggleMore = () => {
    //    setOpen(!open);
    //    if (!open) {
    //        setTimeout(() => {
    //            Glightbox();
    //        });
    //    }
    //}
    //
    ///* eslint-disable react-hooks/rules-of-hooks */
    //React.useEffect(() => {
    //    if (hasOpenElement()) {
    //        setOpen(true);
    //        setTimeout(() => {
    //            Glightbox();
    //        });
    //    }
    //}, [setOpen]);
    ///* eslint-enable react-hooks/rules-of-hooks */
    //
    //if (open) {
    //    const button = (
    //        <div className="moreButton-Container">
    //            <button className="btn moreButton" tabIndex="0"
    //                    aria-label="schließt den zusätzlichen Inhalt oberhalb des Buttons"
    //                    onClick={toggleMore}>
    //                <span className="icon-arrow_right icon-bg">{lessText}</span>
    //            </button>
    //        </div>
    //    );
    //
    //    if (move) {
    //        return showOnEnv(env,
    //            (
    //                <div className="moreButton-show">
    //                    {children}
    //                    {button}
    //                </div>
    //            )
    //        );
    //
    //    } else {
    //        return showOnEnv(env,
    //            (
    //                <div className="moreButton-show">
    //                    {button}
    //                    {children}
    //                </div>
    //            )
    //        );
    //
    //    }
    //}
    //
    //return showOnEnv(env,
    //    (
    //        <div className="moreButton-Container">
    //            <button className="btn moreButton open" tabIndex="0"
    //                    aria-label="öffnet den zusätzlichen Inhalt oberhalb des Buttons"
    //                    onClick={toggleMore}>
    //                <span className="icon-arrow_right icon-bg">{moreText}</span>
    //            </button>
    //        </div>
    //    )
    //);
}

/**
 *
 * @param config
 * @param classNames
 * @param id
 * @param sliderTitleId
 * @param env
 * @param children
 * @returns {boolean}
 * @constructor
 */
const Slider = ({config, classNames, id, sliderTitleId, env, children}) => {

    const sliderId = 'slider-' + id;
    classNames = classNames === undefined ? 'glide slider' : 'glide slider ' + classNames;

    React.useEffect(() => {
        Glide.init(sliderId, sliderTitleId, config, Glightbox);
    }, [sliderId, sliderTitleId, config]);

    return showOnEnv(env,
        (<div className={classNames} id={sliderId}>
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides" key={sliderId}>
                        {children}
                    </ul>
                </div>
                <div className="glide-navigation">
                    <div data-glide-el="controls">
                        <button className="glide__arrow glide__arrow--left glide__arrow--hidden"
                                data-glide-dir="<">Zurück
                        </button>
                    </div>
                    {Glide.addBullets(children.length, config)}
                    <div data-glide-el="controls">
                        <button className="glide__arrow glide__arrow--right"
                                data-glide-dir=">">Vor
                        </button>
                    </div>
                </div>
            </div>
        )
    );
}

/**
 *
 * @param classNames
 * @param id
 * @param env
 * @param children
 * @returns {boolean}
 * @constructor
 */
const Novel = ({classNames, id, env, children}) => {

    children = children === undefined ? [] : children;
    children = Array.isArray(children) ? children : [children];

    const sliderId = 'novel_' + id;

    classNames = classNames === undefined ? 'glide slider' : 'glide slider ' + classNames;

    let novelGlide = useRef(null);

    React.useEffect(() => {

        let config = {};

        if (isTerminal()) {
            config = {
                type: 'slider',
                rewind: false,
                perView: 1.5,
                focusAt: 'center',
                gap: 10,
                animationDuration: 600,
                animationTimingFunc: 'ease-in-out',
                breakpoints: {}
            };
        } else {
            config = {
                type: 'slider',
                rewind: false,
                perView: 2,
                focusAt: 'center',
                gap: 10,
                animationDuration: 600,
                animationTimingFunc: 'ease-in-out',
                breakpoints: {
                    1449: {
                        perView: 1,
                        peek: {
                            before: '30px',
                            after: '30px',
                        },
                        gap: 10,
                    },
                    767: {
                        perView: 1,
                        peek: {
                            before: '0',
                            after: '0',
                        },
                        gap: 0,
                    }
                }
            };

        }

        novelGlide.current = Glide.init(sliderId, null, config, null);
    }, [sliderId]);

    return showOnEnv(env,
        (
            <div className={classNames} id={sliderId} role="presentation">
                <div className="novel-glide__track" data-glide-el="track">
                    <ul className="novel-glide__slides" key={sliderId}>
                        {children.map(function (item, index) {
                            return (
                                <li className="novel-glide__slide" key={index}>
                                    {item}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="glide-navigation novel-glide-navigation">
                    <div data-glide-el="controls">
                        <button className="glide__arrow glide__arrow--left"
                                data-glide-dir="<">Zurück
                        </button>
                    </div>
                    <div className="glide-controls glide__bullets" data-glide-el="controls[nav]">
                        {children.map(function (item, index) {
                            /* eslint-disable  jsx-a11y/control-has-associated-label */
                            return (
                                <button key={index} className="glide__bullet bull0" data-glide-dir={'=' + index}>
                                    <span class="sr-invisible">Seite {index}</span>
                                </button>
                            )
                            /* eslint-enable  jsx-a11y/control-has-associated-label */
                        })}
                    </div>
                    <div data-glide-el="controls">
                        <button className="glide__arrow glide__arrow--right"
                                data-glide-dir=">">Vor
                        </button>
                    </div>
                </div>
            </div>
        )
    );
}

/**
 *
 * @returns {*|boolean}
 */
const isTerminal = () => {
    return configJSON['isTerminal'] !== undefined ? configJSON['isTerminal'] : false;
}

/**
 *
 * @returns {*|boolean}
 */
const isLandscape = () => {
    return configJSON['isLandscape'] !== undefined ? configJSON['isLandscape'] : false;
}

/**
 *
 * @param env
 * @param content
 * @returns {null|*}
 */
const showOnEnv = (env, content) => {
    if (env === undefined || env === null) {
        return content;
    }

    if (env === 'l' && isTerminal() && isLandscape()) {
        return content;
    }

    if ((env === 'w' && isTerminal()) || (env === 't' && !isTerminal())) {
        return null;
    }

    return content;
}

/**
 *
 * @param img
 */
const setImgContext = (img) => {
    if (typeof window !== `undefined`) {
        if (img === undefined || img === null) {
            document.querySelectorAll('img').forEach((el) => {
                el.addEventListener('contextmenu', (event) => {
                    event.preventDefault();
                    return false;
                });
            })
        } else {
            img.addEventListener('contextmenu', (event) => {
                event.preventDefault();
                return false;
            });
        }
    }
}

/**
 *
 * @param elId
 * @returns {boolean}
 */
const openElement = (elId, callback) => {
    if (typeof window !== `undefined`) {
        const params = new URLSearchParams(window.location.search);
        if (elId === decodeURI(params.get('elid'))) {
            setTimeout(() => {
                callback();
            });
        }
    }
}
//
///**
// *
// * @returns {boolean}
// */
//const hasOpenElement = () => {
//    if (typeof window !== `undefined`) {
//        const params = new URLSearchParams(window.location.search);
//        return params.get('elid') !== null || params.get('imid') !== null;
//    } else {
//        return false;
//    }
//}

/**
 *
 * @param text
 * @returns {*}
 */
const buildId = (text) => {
    return 'el-' + btoa(text);
}

export
{
    Part,
    Fullwidth,
    Row,
    If,
    HeaderPart,
    Video,
    VideoYoutube,
    Facsimile,
    More,
    Slider,
    Novel,
    isTerminal,
    isLandscape,
    showOnEnv,
    setImgContext,
    openElement,
    getPoster
}
