import Glightbox from 'glightbox';
import {setImgContext} from "./container/container";

const init = () => {

    if (!!window.msCrypto) {
        return;
    }

    let activeElement = null;

    Glightbox({
        touchNavigation: true,
        loop: false,
        autoplayVideos: true,
        type: 'image',
        effect: 'fade',
        width: 'auto',
        height: '50vh',
        zoomable: true,
        moreText: 'mehr lesen',
        closeOnOutsideClick: true,
        plyr: {
            css: '',
            js: !!window.msCrypto ? '/js/plyr.polyfilled.js' : '/js/plyr.min.js',
            config: {
                iconUrl: '/images/plyr.svg',
                ratio: '16:9', // or '4:3'
                muted: false,
                hideControls: true,
                youtube: {
                    noCookie: true,
                    rel: 0,
                    showinfo: 0,
                    iv_load_policy: 3
                },
                vimeo: {
                    byline: false,
                    portrait: false,
                    title: false,
                    speed: true,
                    transparent: false
                }
            }
        },
        onOpen: () => {
            activeElement = document.activeElement
        },
        onClose: () => {
            if (activeElement !== null) {
                activeElement.focus();
            }
        },
        afterSlideLoad: (slide) => {
            if (slide['slide'] !== undefined && slide['slide'] !== null) {
                setImgContext(slide['slide'].querySelector('img'));
            }
        }

    });
}

export default init

